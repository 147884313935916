import React from "react"

interface ICategoria {
    nome: string
    imagem: string
    onClick?: () => void
}


export default function CardCategoria({imagem, nome, onClick}: ICategoria) {
    return (
        <div 
        onClick={onClick}
        className="cursor-pointer hover:scale-105 min-h-[90px]">
            <div className="flex justify-center items-center m-4">
                <img
                className="
                h-[70px]
                w-[70px]
                md:w-[90px]        
                md:h-[90px]                    
                border-[3px]
                shadow-md
                rounded-full 
                min-w-[70px]
                min-h-[70px]
                md:max-w-[90px]
                md:max-h-[90px]
                border-purple-500" 
                src={imagem} 
                alt={nome} />
            </div>
            <div className="w-full flex items-center justify-center truncate m-1">
                {nome}
            </div>
        </div>
    )
}