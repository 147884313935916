import React, { useContext } from "react";
import { Produto } from "../data";
import { GlobalContext } from "../../../services/context/GlobalContext";

export default function CardProduto({
        nomeProduto, 
        precoProduto, 
        porcentagemDesconto, 
        precoDesconto,
        imagem,
        dataPromocao,
        descricao,
        enderecoVendedor,
        nomeVendedor,
        categoria,
        imagemVendedor,
        voucher
    } :Produto){


        const {setSelectedProduct, setShowCuponModal} = useContext(GlobalContext)

        function convertISOdate(ISOdate: String){
            const [ano, mes, dia] = ISOdate.split('-')
            return `${dia}/${mes}/${ano}`
        }

        return(
            <div 
            onClick={() => {
                setSelectedProduct(
                    {
                        nomeProduto, 
                        precoProduto, 
                        porcentagemDesconto, 
                        precoDesconto,
                        imagem,
                        dataPromocao,
                        descricao,
                        enderecoVendedor,
                        nomeVendedor,
                        categoria,
                        imagemVendedor,
                        voucher
                    }
                )
                setShowCuponModal(true)
            }}
            className=" w-4/5 md:w-1/6 min-w-[300px] m-3 p-4 bg-white rounded-md shadow-md hover:scale-105 cursor-pointer md:min-h-[420px]">
                <div className="mb-2 flex flex-col">
                    <div className="w-full flex justify-center">
                        <img className="h-[200px] w-[200px] rounded transition-transform duration-300 transform hover:scale-110 m-4" src={imagem} alt={nomeProduto}/>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <div className="text-xl font-bold">
                            {nomeProduto}
                        </div>
                        <div className="flex space-x-1 ">
                            <div className="text-red-600 line-through 400">
                                R$ {precoProduto},00
                            </div>
                            <div className="text-sm bg-red-500 flex items-center p-1 rounded-sm text-white">
                                {parseInt(porcentagemDesconto?.toString() || '0')}% OFF
                            </div>
                        </div>
                        <div className="text-xl font-bold">
                            R$ {precoDesconto},00
                        </div>
                        <div className="text-sm">
                            vendido por: {nomeVendedor}
                        </div>
                        <div className="text-sm">
                            valido até: {convertISOdate(dataPromocao?.toString() || '')}
                        </div>
                    </div>
                </div>
                
                <div className="border-t h-full flex items-center justify-center">
                    <div className="font-bold p-2 text-xl hover:underline">
                        Obter Cupom
                    </div>
                </div>
                
            </div>
        )

}