import React, { createContext, useState } from "react";
import { Loja, Produto } from "../../views/homePage/components/types";


interface IGlobalContextProps {
    produtos: Produto[]
    setProdutos: (produtos: Produto[]) => void
    productList: Produto[]
    setProductList: (produtos: Produto[]) => void
    selectedProduct: Produto | undefined
    setSelectedProduct: (produto: Produto) => void
    showCuponModal: boolean,
    setShowCuponModal: (show: boolean) => void
    categoria: string
    setCategoria: (categoria: string) => void
    pesquisa: string
    setPesquisa: (presquisa: string) => void
    lojas: Loja[]
    setLojas: (lojas: Loja[]) => void
}

interface IGlobalProviderProps {
    children: React.ReactNode
}

const defaultValues: IGlobalContextProps = {
    produtos: [],
    setProdutos: () => {},
    productList: [],
    setProductList: () => {},
    selectedProduct: undefined,
    setSelectedProduct: () => {},
    showCuponModal: false,
    setShowCuponModal: () => {},
    categoria: '',
    setCategoria: () => {},
    pesquisa: '',
    setPesquisa: () => {},
    lojas: [],
    setLojas: () => {}
}

export const GlobalContext = createContext(defaultValues)

export function GlobalContextProvider({children}: IGlobalProviderProps){

    const [produtos, setProdutos] = useState<Produto[]>([])
    const [productList, setProductList] = useState<Produto[]>([])
    const [selectedProduct, setSelectedProduct] = useState<Produto | undefined>(undefined)
    const [showCuponModal, setShowCuponModal] = useState<boolean>(false)
    const [categoria, setCategoria] = useState<string>('')
    const [pesquisa, setPesquisa] = useState<string>('')
    const [lojas, setLojas] = useState<Loja[]>([])

    return(
        <GlobalContext.Provider value={{produtos, setProdutos, productList, setProductList, selectedProduct, setSelectedProduct, showCuponModal, setShowCuponModal, pesquisa, setPesquisa, categoria, setCategoria, lojas, setLojas}}>
            {children}
        </GlobalContext.Provider>
    )
}