import HomePage from "./views/homePage/homePage";
import { GlobalContextProvider } from "./services/context/GlobalContext"

function App() {
  return (
    <GlobalContextProvider>
      <HomePage/>
    </GlobalContextProvider>
  )
}

export default App;
