import React from "react"
import Searchbar from "./Searchbar"
import { MdDiscount, MdAddBusiness, MdAddLocation, MdMap } from "react-icons/md"
import { RiDiscountPercentFill } from "react-icons/ri"
import {IoIosLogIn} from "react-icons/io";

export default function Header(){
    return(

        <div
            className="w-full m-10 justify-center z-20 text-center flex items-center lg:items-center lg:pl-20 bg-purple-500 p-4 shadow-md flex-col">


            <nav className="bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">

                    {/* Logo */}
                    <a href="/" className="flex items-center space-x-3">
                        <img
                            className="h-12 w-auto md:h-16 md:w-auto object-contain"
                            src="/mczprice.png"
                            alt="logo"
                        />
                    </a>

                    {/* Navegação */}
                    <div className="hidden h-auto w-800 md:block">
                        <Searchbar/>
                    </div>

                    {/* Botões */}
                    <div className="flex space-x-3 md:order-2 mt-4 md:mt-0">
                        <button onClick={() => window.location.href = '/paineldecontrole/login'}
                                className="flex items-center py-2 px-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm">
                            <IoIosLogIn className="mr-2"/>
                            Entrar na plataforma
                        </button>
                        <button onClick={() => window.location.href = '/paineldecontrole/cadastre-se'}
                                className="flex items-center py-2 px-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm">
                            <MdAddBusiness className="mr-2"/>
                            Cadastrar oferta
                        </button>
                    </div>

                    {/* Menu Responsivo */}
                    <div className="w-full flex-col md:hidden mt-4" id="navbar-sticky">
                        <ul className="flex flex-col items-start space-y-2 font-medium border-t border-gray-200 pt-4">
                            <li>
                                <Searchbar/>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>


        </div>
    )
}