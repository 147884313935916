import React, { useContext, useEffect } from "react";
import { pages } from "./data"
import { GlobalContext } from "../../services/context/GlobalContext";
import ProductList from "./components/ProductList";
import Header from "../../components/Header/Header";
import CategorieList from "./components/CategorieList";
//import StoresList from "./components/StoresList";
import CuponModal from "../../components/Modal/CuponModal";
import Footer from "./components/Footer";
import Carousel from "./components/Carousel";

export default function HomePage(){
    
    const { showCuponModal, setShowCuponModal} = useContext(GlobalContext)
    
    // useEffect(() => {
    //     setProdutos(pages[0].produtos)
    //     setProductList(pages[0].produtos)
    // }, [setProdutos, setProductList])

    useEffect(() => {
        if ("geolocation" in navigator) {
        
          navigator.geolocation.getCurrentPosition(
            (position) => {
              
              const { latitude, longitude } = position.coords;
              console.log("Latitude:", latitude, "Longitude:", longitude);
              
            },
            (error) => {
              console.error("Erro ao obter localização:", error);
            }
          );
        } else {
          console.log("Geolocalização não é suportada por este navegador.");
        }
      }, [])

    return(
        <div className="flex flex-col items-center">
            <CuponModal
            openModal={showCuponModal}
            closeModal={() => {
                setShowCuponModal(false)
            }}/>
            <Header/>
          

            {/* <StoresList/> */}
            <div className="flex flex-col justify-center relative top-20 md:top-40 w-full">
               
              <CategorieList/>
              <ProductList/>
              <Footer/>
            </div>

            

        </div>
        
    )
}