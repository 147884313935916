import React, { useContext } from "react";
import { IoClose, IoLocation,  IoLinkSharp } from "react-icons/io5";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { GlobalContext } from "../../services/context/GlobalContext";
import { MdWhatsapp } from "react-icons/md";

interface ICuponModalProps {
    openModal: boolean
    closeModal: () => void

}

export default function CuponModal({ closeModal, openModal} :ICuponModalProps){
    
    const {selectedProduct} = useContext(GlobalContext)

    function convertISOdate(ISOdate: String){
        const [ano, mes, dia] = ISOdate.split('-')
        return `${dia}/${mes}/${ano}`
    }
 
    if(!openModal) return null
    
    return(
        <div 
        onClick={closeModal}
        className="z-50 flex justify-center items-center h-[100vh] w-full fixed bg-black bg-opacity-20">
            <div 
            onClick={(event) => {event.stopPropagation()}}
            className="bg-white w-4/5 h-4/5 md:w-3/5 md:h-3/5 rounded-md shadow-md overflow-hidden pr-1 pt-1 overflow-y-scroll"
            >
              <div className="w-full flex justify-end">
                <div 
                onClick={closeModal}
                className="hover:bg-red-400 hover:text-white p-1 rounded-full cursor-pointer">
                    <IoClose />
                </div>
              </div>
            {selectedProduct && (
                <div className="flex flex-col h-[90%] w-full items-center justify-between p-4">
                    <div className="w-full">
                        <div className="flex rounded w-full justify-center items-center">
                            <div className="mb-3 rounded hover:scale-105 w-[200px] h-[200px]">
                                <img src={selectedProduct.imagem} alt={selectedProduct.nomeProduto} />
                            </div>
                        </div>
                        <div className="w-full font-bold text-xl">
                            {selectedProduct.nomeProduto}
                        </div>
                        <div className="w-full flex space-x-1 pt-2">
                            <div className="text-red-500 line-through text-md">R$ {selectedProduct.precoProduto},00</div>
                            <div className="bg-red-500 text-sm p-1 text-white rounded">{parseInt(selectedProduct.porcentagemDesconto.toLocaleString())} % OFF</div>                        
                        </div>
                        <div className="w-full pt-2">
                            <div className="text-[1.4rem] font-bold">
                                R$ {selectedProduct.precoDesconto},00
                            </div>
                        </div>
                        <div className="w-full pt-2">
                            <div className="text-sm">
                                Vendido por: {selectedProduct.nomeVendedor}
                            </div>
                            <div className="text-sm">
                                {selectedProduct.enderecoVendedor}
                            </div>
                        </div>
                        <div className="w-full flex pt-4 mb-4 space-x-4">
                                <div className="cursor-pointer hover:scale-105"> <IoLocation  size={25}/> </div>
                                <div className="cursor-pointer hover:scale-105"> <IoLinkSharp size={25}/> </div>
                                <div className="cursor-pointer hover:scale-105"> <FaInstagram size={25}/> </div>
                                <div className="cursor-pointer hover:scale-105"> <FaWhatsapp  size={25}/> </div>
                        </div>
                    </div>

                    <div  className="w-full">
                        <div className="w-full border-t pb-10 flex flex-col items-center justify-center space-y-2">
                            <div className="pt-2 text-lg font-[600]">Voucher</div>
                            <div className="text-[1.4rem] font-bold">{selectedProduct.voucher}</div>
                            <div className="text-sm">Válido até: {convertISOdate(selectedProduct.dataPromocao || '')}</div>

                            <button
                            onClick={() => {
                                window.location.href = `https://api.whatsapp.com/send?phone=55${selectedProduct?.whatsapp}&text=Oi,%20quero%20esse%20voucher,%20! ${selectedProduct.voucher}`;
                            }}
                            className="flex m-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                            >
                            <MdWhatsapp /> Entrar em contato
                            </button>
                        </div>
                    </div>




                    
                </div>
            )}

            </div>
        </div>
    )
}