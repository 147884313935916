import React, { useContext, useRef, useState, useEffect } from "react"
import { GlobalContext } from "../../../services/context/GlobalContext"
import CardProduto from "../components/CardProduto"
import { IoMdArrowDropdown,  IoMdArrowDropup  } from "react-icons/io"
import axios from "axios"
import { IResponseProduct, Loja, Produto } from "./types"
import { RiDiscountPercentFill } from "react-icons/ri"
import Searchbar from "../../../components/Header/Searchbar"

export default function ProductList(){

    const {produtos, setProdutos, lojas , categoria, productList, setProductList, pesquisa} = useContext(GlobalContext)
    const [orderMode, setOrderMode] = useState<string>('Mais Recente')
    const [showSelectDropDown, setShowSelectDropDown] = useState<boolean>(false)
    const selectDropDownRef = useRef<HTMLDivElement | null>(null)

    function handleClickOutside (event: MouseEvent | TouchEvent) {
        if (selectDropDownRef.current && !selectDropDownRef.current.contains(event.target as never)) {
            setShowSelectDropDown(false)
        }
    }

    function handleChangeOrderMode( orderMode: string) {
        setOrderMode(orderMode)
        setShowSelectDropDown(false)
    }

    function handleOrderList(orderMode: string) {

        let sortedList = [...productList]
    
        switch (orderMode) {
            case 'Ordem Alfabética A-Z':
                sortedList.sort((a, b) => {
                    return a.nomeProduto.localeCompare(b.nomeProduto)
                })
                break
    
            case 'Ordem Alfabética Z-A':
                sortedList.sort((a, b) => {
                    return b.nomeProduto.localeCompare(a.nomeProduto)
                })
                break
    
            case 'Mais Recente':

                sortedList = [...productList]
                break
    
            case 'Menos Recente':
                sortedList.reverse()
                break
    
            case 'Menor Preço':
                sortedList.sort((a, b) => {
                    return (a.precoDesconto ?? 0) - (b.precoDesconto ?? 0)
                })
                break
    
            case 'Maior Preço':
                sortedList.sort((a, b) => {
                    return (b.precoDesconto ?? 0) - (a.precoDesconto ?? 0)
                })
                break
    
            default:
                break
        }
    
        setProductList(sortedList)
    }

    async function getProducts(){
        try {
            const {data} = await axios.get('https://euqueropreco.nuxtsoft.com.br/api/products')
            console.log(data)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await getProducts()
                console.log(data)
                let produtos: Produto[] = []
                data.forEach((produtoResponse: IResponseProduct) => {
                    const produto: Produto = {
                        nomeProduto: produtoResponse.name,
                        imagem: `https://www.euqueropreco.nuxtsoft.com.br/storage/${produtoResponse.imagem}`,
                        precoProduto: parseFloat(produtoResponse.old_price),
                        precoDesconto: parseFloat(produtoResponse.price),
                        porcentagemDesconto: parseInt(produtoResponse.discount),
                        categoria: 0,
                        nomeVendedor: '',
                        voucher: produtoResponse.voucher,
                        dataPromocao: produtoResponse.published_at,

                    }

                    lojas.forEach((loja: Loja) => {
                        if(produtoResponse.tenant_id === loja.id){
                            produto.categoria = loja.nome
                            produto.nomeVendedor = loja.nome
                            produto.whatsapp = loja.whatsapp
                            produto.tel = loja.tel
                        }
                    })

                    produtos.push(produto)

                })
                setProdutos(produtos)
            } catch (error) {
                console.log(error)
            }
        }

        getData()
    }, [lojas])
    
    useEffect(() => {
            
        if(orderMode) handleOrderList(orderMode) 
    
    }, [orderMode])

    useEffect(() => {
        if (showSelectDropDown) {
            document.addEventListener("mousedown", handleClickOutside)
            document.addEventListener("touchstart", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
            document.removeEventListener("touchstart", handleClickOutside)
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
            document.removeEventListener("touchstart", handleClickOutside)
        }
    }, [showSelectDropDown])

    return(
        <div className="w-full h-full flex bg-gray-200 justify-center items-center flex-col">

                    <div className="bg-indigo-900 text-center py-4 lg:px-4 rounded">
                    <div className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                        <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Os melhores preços, de Maceió-AL</span>
                        <span className="font-semibold mr-2 text-left flex-auto flex m-2"><RiDiscountPercentFill />Descontos Exclusivos <RiDiscountPercentFill /></span>
                        <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
                    </div>
                    </div>

                    <div className="m-2">
                    <Searchbar/>
                    </div>

                   

            {produtos && (
                <div className="flex items-center justify-start min-w-[100%] w-full md:justify-start flex-col">

                    <div className="w-4/5 md:w-4/5 py-4 md:p-4 text-sm font-[600]">
                        {pesquisa && (<div>Pesquisando por {pesquisa}...</div>)}
                        <div className="flex space-x-1">
                            <div>
                                Exibindo {productList.length} - {productList.length} Ofertas
                            </div>
                             {categoria && (<div>de {categoria}</div>)}
                        </div>
                        <div className="flex w-auto space-x-1 items-center">
                            <div>Ordenando por:</div>
                            <div 
                            onClick={() => {setShowSelectDropDown((prev) => !prev)}} 
                            className="cursor-pointer hover:underline"> 
                                {orderMode}
                            </div>
                            <div>{showSelectDropDown ?  <IoMdArrowDropup/> : <IoMdArrowDropdown />}</div>
                        </div>
                        {showSelectDropDown && (
                            <div 
                            ref={selectDropDownRef}
                            className="absolute z-10 bg-white py-4 rounded-md shadow-md">
                                <div className="py-1 px-4 cursor-pointer hover:bg-gray-200 hover:underline"
                                onClick={() => {handleChangeOrderMode('Mais Recente')}}
                                >Mais Recente</div>
                                <div className="py-1 px-4 cursor-pointer hover:bg-gray-200 hover:underline"
                                onClick={() => {handleChangeOrderMode('Menos Recente')}}
                                >Menos Recente</div>
                                <div className="py-1 px-4 cursor-pointer hover:bg-gray-200 hover:underline"
                                onClick={() => {handleChangeOrderMode('Menor Preço')}}
                                >Menor Preço</div>
                                <div className="py-1 px-4 cursor-pointer hover:bg-gray-200 hover:underline"
                                onClick={() => {handleChangeOrderMode('Maior Preço')}}
                                >Maior Preço</div>
                                <div className="py-1 px-4 cursor-pointer hover:bg-gray-200 hover:underline"
                                onClick={() => {handleChangeOrderMode('Ordem Alfabética A-Z')}}
                                >Ordem Alfabética A-Z</div>
                                <div className="py-1 px-4 cursor-pointer hover:bg-gray-200 hover:underline"
                                onClick={() => {handleChangeOrderMode('Ordem Alfabética Z-A')}}
                                >Ordem Alfabética Z-A</div>
                            </div>
                        )}
                    </div>
                    <div className="h-full bg-gray-200 w-4/5 flex items-center justify-center lg:justify-start flex-col md:flex-row md:flex-wrap">
                       { productList.map((produto, index) => {
                    return(
                        <CardProduto
                        key={index}
                        nomeProduto={produto.nomeProduto}
                        precoProduto={produto.precoProduto}
                        descricao={''}
                        porcentagemDesconto={produto.porcentagemDesconto}
                        precoDesconto={produto.precoDesconto}
                        dataPromocao={produto.dataPromocao || ''}
                        imagem={produto.imagem}
                        nomeVendedor={produto.nomeVendedor}
                        categoria={produto.categoria}
                        enderecoVendedor={produto.enderecoVendedor}
                        imagemVendedor={produto.imagemVendedor}
                        voucher={produto.voucher}
                        />
                    )
                       })}                
                    </div>
                </div>
                

            )}
        </div>

    )
}