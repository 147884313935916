import React, { useContext, useEffect } from "react"
import { IoMdSearch } from "react-icons/io"
import { GlobalContext } from "../../services/context/GlobalContext"

export default function Searchbar(){
    
    const {pesquisa, setPesquisa, produtos, setProductList} = useContext(GlobalContext)

    useEffect(() => {
        if (pesquisa) {
            const produtosCopy = [...produtos]
            const pesquisaFilter = produtosCopy.filter((item) => 
                item.nomeProduto.toLowerCase().includes(pesquisa.toLowerCase()))
            setProductList(pesquisaFilter)
        }

        if(!pesquisa){
            setProductList(produtos)
        }
    }, [pesquisa, produtos])

    console.log(produtos)
   
   
    return(
        <div className="w-full m-2 bg-white rounded-full shadow-md flex items-center justify-center p-1 pl-4">
            <input
            value={pesquisa}
            onChange={(event) => {
                setPesquisa(event.target.value)
            }} 
            className="w-full h-full text-black p-1 border-r"
            placeholder='Procure por uma oferta'
            type="text"/>
            <div className="w-1/5 md:w-20 h-full flex justify-center items-center text-black hover:text-gray-400 cursor-pointer">
                <IoMdSearch/>
            </div>
        </div>
    )
}